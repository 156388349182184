import axios from '@/utils/request';

export async function ali1688Search({
    shopId,
    itemId
}, params) {
    return axios.get(params ?
        `/product/ali1688/search/${shopId}/${itemId}?${params}` :
        `/product/ali1688/search/${shopId}/${itemId}`);
}

export async function sync1688ToShopify(ali1688ItemId, payload) {
    return axios.post(`/product/sync1688ToShopify/${ali1688ItemId}`, payload);
}

export async function query1688SyncResult(ali1688ItemId) {
    return axios.get(`/product/query1688SyncResult/${ali1688ItemId}`);
}

export async function queryAli1688SearchResult(shopId, itemId) {
    return axios.get(`/product/queryAli1688SearchResult/${shopId}/${itemId}`);
}
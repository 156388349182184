<template>
  <div>
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :pulling-text="$t('下拉即可刷新...')"
      :loosing-text="$t('释放即可刷新...')" :loading-text="$t('加载中...')">
      <div class="shopee-item" v-if="compareProduct && compareProduct.itemId">
        <van-image width="100" height="100" fit="scale-down" :src="compareProduct.image" @click="toShopee" />
        <div class="shopee-item-content">
          <div class="product-title">
            {{ compareProduct.title ? compareProduct.title : $t("您正在寻找的商品") }}
          </div>
          <div style="padding: 5px 0px">
            <span class="shopee-price" v-if="compareProductValid(compareProduct.price)">฿{{ compareProduct.price }}</span>
            <span class="shopee-sold" v-if="compareProduct.sold">{{ compareProduct.sold }} {{ $t("sold") }}</span>
          </div>
          <div v-if="compareProduct.ratingStar">
            <van-rate v-model="rate" allow-half color="#F9BA4A" size="12" void-icon="star" void-color="#eee"
              readonly /><span style="font-size: 0.8rem">{{ $t("ratings", { ratings: getShopeeRating() }) }}
            </span>
          </div>
        </div>
      </div>

      <van-list v-model="loading" :finished="finished" :immediate-check="false"
        :finished-text="products.length ? $t('没有更多了') : ''" @load="onLoad">
        <van-grid v-if="products && products.length" :column-num="2" :gutter="10">
          <van-grid-item v-for="product in products" :key="product.id || product.num_iid"
            @click="sync1688ToShopify(product)">
            <van-image width="100%" height="150px" :src="product.pic_url || product.img" fit="cover" />
            <div class="product-name">
              {{ product.local_title ? product.local_title : product.title }}
            </div>
            <div class="product-price">
              <span style="flex: 1">฿{{ getDisplayPrice(product.price) }}</span>
            </div>
          </van-grid-item>
        </van-grid>
        <van-empty v-if="!products.length" image="search" :description="$t('暂无数据')" />
      </van-list>
    </van-pull-refresh>

    <div class="van-toast-cancel" v-if="cancelLoadingShow">
      <span class="van-loading__spinner van-loading__spinner--circular">
        <svg viewBox="25 25 50 50" class="van-loading__circular">
          <circle cx="50" cy="50" r="20" fill="none"></circle>
        </svg>
      </span>
      <span class="cancel-loading">{{ cancelLoadingText }}</span>
      <span class="cancel-loading-cancel" @click="cancelWait()">{{
        $t("取消等待")
      }}</span>
    </div>

    <van-image-preview v-model="imagePreviewShow" closeable
      :images="compareProduct && compareProduct.image ? [compareProduct.image] : []">
    </van-image-preview>
  </div>
</template>

<script>
import {
  Icon,
  CellGroup,
  PullRefresh,
  Grid,
  GridItem,
  Empty,
  Toast,
  Image,
  List,
  Rate,
  ImagePreview
} from "vant";
import {
  ali1688Search,
  sync1688ToShopify,
  query1688SyncResult,
  queryAli1688SearchResult,
} from "./service";
import { transRMBtoTHB, formatPriceCeil } from "@/utils/utils";
import { COMPANY } from "@/utils/constants";
import { openWebPage } from "@/utils/pages";
import { getChatWoot } from "@/utils/storage";
import { trackSearch } from "@/utils/statistic";

export default {
  components: {
    [Icon.name]: Icon,
    [CellGroup.name]: CellGroup,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Empty.name]: Empty,
    [PullRefresh.name]: PullRefresh,
    [Image.name]: Image,
    [List.name]: List,
    [Rate.name]: Rate,
    [ImagePreview.Component.name]: ImagePreview.Component,
  },
  data() {
    return {
      refreshing: false,
      loading: false,
      finished: true,
      shopId: this.$route.query.shopId,
      itemId: this.$route.query.itemId,
      products: [],
      compareProduct: null,
      ali1688ItemId: null,
      querySyncFinish: false,
      cancelLoadingShow: false,
      retryTime: 0,
      maxRetryTime: 33,
      rate: 0,
      taskId: 0,
      cancelLoadingText: this.$t("加载中..."),
      imagePreviewShow: false
    };
  },
  methods: {
    compareProductValid(price) {
      return price && price > 0;
    },
    getShopeeRateNumber() {
      if (this.compareProduct && this.compareProduct.ratingStar)
        return Math.round(this.compareProduct.ratingStar * 10) / 10;
    },
    getShopeeRating() {
      if (this.compareProduct && this.compareProduct.ratingStar)
        return parseFloat(this.compareProduct.ratingStar).toFixed(1);
    },
    toShopee() {
      if (this.compareProduct && this.compareProduct.url)
        openWebPage(this.compareProduct.url);
      else
        this.imagePreviewShow = true
    },
    getDisplayPrice(price) {
      const thb = transRMBtoTHB(price);
      return formatPriceCeil(thb);
    },
    onLoad() { },
    onRefresh() {
      this.ali1688Search();
    },
    async ali1688Search() {
      let payLoad = { shopId: this.shopId, itemId: this.itemId };

      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const chatwoot = getChatWoot();
      let params;
      if (chatwoot) {
        const { chatChannel, chatConversationId, chatUserId } = chatwoot;
        params = `chatChannel=${chatChannel}&chatConversationId=${chatConversationId}&chatUserId=${chatUserId}`;
      }
      const res = await ali1688Search(payLoad, params);
      Toast.clear();
      const { success, data, message, extra } = res;

      this.refreshing = false;
      if (success) {
        const { searchResult: result, taskId } = data;
        this.taskId = taskId;
        const searchResult = JSON.parse(result);

        const { compareProduct, productList } = searchResult;

        this.compareProduct = compareProduct;
        this.rate = this.getShopeeRateNumber();
        let products = productList.filter((product) => {
          return product.id || product.num_iid;
        });
        products = products.sort((a, b) => {
          return a.price - b.price;
        });
        this.products = products;

        if (extra && extra.researchTaskId) {
          this.querySyncAli88Result();
        }
      } else Toast.fail(message);
    },
    async queryAli1688SearchResult() {
      const res = await queryAli1688SearchResult(this.shopId, this.itemId);
      const { success, data, message } = res;

      if (success) {
        if (!data) return;

        clearInterval(this.interval);
        const { searchResult: result, taskId } = data;
        this.taskId = taskId;
        const searchResult = JSON.parse(result);

        const { compareProduct, productList } = searchResult;
        this.compareProduct = compareProduct;
        this.rate = this.getShopeeRateNumber();
        let products = productList.filter((product) => {
          return product.id || product.num_iid;
        });
        products = products.sort((a, b) => {
          return a.price - b.price;
        });
        this.products = products;

        this.cancelLoadingShow = false;
        this.querySyncFinish = true;
      } else Toast.fail(message);
    },
    querySyncAli88Result() {
      this.cancelLoadingText = this.$t("正在更新数据...");
      this.cancelLoadingShow = true;
      this.retryTime = 0;
      this.querySyncFinish = false;

      this.interval = setInterval(() => {
        this.retryTime++;
        if (this.querySyncFinish) {
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        }
        if (this.retryTime >= this.maxRetryTime) {
          Toast.fail(this.$t("Sync failed"));
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        } else this.queryAli1688SearchResult();
      }, 3000);
      this.queryAli1688SearchResult();
    },
    async sync1688ToShopify(product) {
      if (this.interval) {
        clearInterval(this.interval);
        this.interval = null;
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const { id, num_iid, detail_url, url } = product;

      const ali1688ItemId = id || num_iid;

      const params = {
        taskId: this.taskId,
        itemId: ali1688ItemId,
        detailUrl: url || detail_url,
        company: COMPANY,
        compareProduct: this.compareProduct,
      };

      const chatwoot = getChatWoot();
      if (chatwoot) {
        params.chatwoot = chatwoot;
      }
      const res = await sync1688ToShopify(ali1688ItemId, params);
      Toast.clear();
      const { success, message } = res;
      if (success) {
        this.ali1688ItemId = ali1688ItemId;
        this.querySyncResult();
      } else Toast.fail(message);
    },
    async query1688SyncResult() {
      if (!this.ali1688ItemId) return;

      const res = await query1688SyncResult(this.ali1688ItemId);
      const { success, data, message } = res;

      if (success) {
        if (!data) return;
        const { targetItemId } = data;
        Toast.clear();
        clearInterval(this.interval);
        if (!this.querySyncFinish)
          this.$router.push({
            name: "Product",
            query: { id: targetItemId },
          });
        this.querySyncFinish = true;
      } else Toast.fail(message);
    },
    querySyncResult() {
      this.cancelLoadingText = this.$t("加载中...");
      this.cancelLoadingShow = true;
      this.retryTime = 0;
      this.querySyncFinish = false;

      this.query1688SyncResult();
      this.interval = setInterval(() => {
        this.retryTime++;
        if (this.querySyncFinish) {
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        }
        if (this.retryTime >= this.maxRetryTime) {
          Toast.fail(this.$t("Sync failed"));
          this.cancelLoadingShow = false;
          clearInterval(this.interval);
        } else this.query1688SyncResult();
      }, 3000);
    },
    cancelWait() {
      this.cancelLoadingShow = false;
      clearInterval(this.interval);
    },
  },
  mounted() {
    this.ali1688Search();
    trackSearch();
  },
};
</script>

<style lang="less" scoped>
.recent-search {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.rencent-content {
  flex: 1;
  display: inline-block;
  padding: 0px 10px;
}

.van-cell-group__title {
  background-color: white;
}

.product-name {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  text-align: start;
  margin: 5px 0px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

:deep(.van-grid-item__content--center) {
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: start !important;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.product-price {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.9rem;
  height: 0.9rem;
}

.product-sold {
  display: inline-block;
  padding: 0px 5px;
}

.van-toast-cancel {
  position: fixed;
  top: 50%;
  left: 50%;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  box-sizing: content-box;
  width: 88px;
  max-width: 70%;
  min-height: 88px;
  padding: 16px;
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  white-space: pre-wrap;
  text-align: center;
  word-break: break-all;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
}

.cancel-loading {
  display: inline-block;
  padding: 5px 0px;
}

.cancel-loading-cancel {
  display: inline-block;
  border: solid white 1px;
  border-radius: 5px;
  padding: 3px 10px;
}

.product-price {
  color: red;
  font-size: 1rem;
  display: inline-block;
  padding: 2px 0px;
}

:deep(.van-grid-item__content--surround::after) {
  border-width: 0px;
}

.shopee-item {
  display: flex;
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
}

.shopee-item-content {
  flex: 1;
  padding: 10px;
}

.product-title {
  color: #333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  text-align: start;
  font-size: 0.9rem;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.shopee-price {
  color: red;
  font-size: 0.9em;
  margin-right: 15px;
}

.shopee-sold {
  display: inline-block;
  font-size: 0.8em;
}


:deep(.van-image-preview) {
  height: 80vh;
  top: 10vh;
}
</style>